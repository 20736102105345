import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import CacheBuster from './cacheBuster';
import Data from './pages/Templates/Templates';

function App() {
  return (
    <Router>
        <CacheBuster />
      <Routes>
        <Route path="/" element={<Data />} />
      </Routes>
    </Router>
  );
}

export default App;
