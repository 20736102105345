import { gql, useQuery } from "@apollo/client";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const GET_HSMTEMPLATES = gql`
  query HsmTemplates {
    hsmTemplateStatus(status: "Approved") {
      id
      name
      namespace
      Language
      message
      previewMessage
      status
    }
  }
`;

const GetTemplates = ({ onChange, value }) => {
  const { data, error, loading } = useQuery(GET_HSMTEMPLATES);

  if (error) return "error";
  if (loading) return "Loading...";

  if (data) {
    const templates = data.hsmTemplateStatus;

    return (
      <div>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-filled-label">
            SELECT TEMPLATE
          </InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={value}
            onChange={onChange}
            name="template"
          >
            {templates.map((template, i) => (
              <MenuItem key={i} value={template}>
                {template.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
};

export default GetTemplates;
