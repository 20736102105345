import { ApolloClient, InMemoryCache } from "@apollo/client";

const options = {
  httpUri: process.env.REACT_APP_GRAPHQL_API || "http://localhost:4000/graphql",
  wsUri: process.env.REACT_APP_GRAPHQL_WS_API || "ws://localhost:4000/graphql",
};

const urlParams = new URLSearchParams(window.location.search);
const tenantId = urlParams.get("tenantId");
localStorage.setItem("tenantId", tenantId);

export const client = new ApolloClient({
  uri: options.httpUri,
  cache: new InMemoryCache(),
  headers: {
    "x-tenant-id": localStorage.getItem("tenantId"), //HARDCODED: wich authorization we're going to use??
  },
});
