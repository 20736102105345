/* eslint-disable */

import pkjson from '../package.json'

export default function CacheBuster() {
	/** Packagejson Version */
	const pkjsonVersion = pkjson.version

	/** local storage Version */
	const localVersion = localStorage.getItem('version')

	/** If local storage version is not equal to packagejson version */
	if (localVersion !== pkjsonVersion) {
		console.log(
			`%c[CACHE] There is a new app version ${localVersion}, refreshing current version ${pkjsonVersion}.`,
			'color:red'
		)

		if (caches) {
            /** Clear cache */
			caches.keys().then(function (names) {
				for (const name of names) caches.delete(name)
			})
		}

		/** Clear local storage */
		localStorage.clear()
		/** Set local storage version to packagejson version */
		localStorage.setItem('version', pkjsonVersion)

		/** Reload the page */
		window.location.reload(true)
	}

	console.log(
		`%c[CACHE] App already at latest version ${pkjsonVersion}.`,
		'color:limegreen'
	)

    return (<></>)
}
