import { gql, useQuery } from "@apollo/client";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const CAMPAIGNS = gql`
  query Campaigns {
    campaigns {
      id
      name
      instance
    }
  }
`;

const GetCampaigns = ({ onChange, value }) => {
  const { data, error, loading } = useQuery(CAMPAIGNS);

  if (error) return "error";
  if (loading) return "Loading...";

  if (data) {
    const campaigns = data.campaigns;

    return (
      <div>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-filled-label">
            SELECT CAMPAIGN
          </InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={value}
            onChange={onChange}
            name="campaign"
          >
            {campaigns.map((campaign, i) => (
              <MenuItem key={i} value={campaign}>
                {campaign.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
};

export default GetCampaigns;
