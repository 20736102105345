import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  GridContainer,
  GridItem,
  Input,
  Timeline,
} from '@icq24/omniq-components';
import _ from 'lodash';
import { useState } from 'react';

import { extractVars } from '../../tools';
import GetCampaigns from '../Components/CampaignSelect';
import GetTemplates from '../Components/TemplatesSelect';

// import { useSnackbar } from 'material-ui-snackbar-provider';

const CREATE_MESSAGE = gql`
  mutation broadcastMesage($broadcastInput: broadcastInput) {
    broadcastMesage(broadcastInput: $broadcastInput)
  }
`;

const TemplatesForm = () => {
  let urlParams = new URLSearchParams(window.location.search);

  urlParams = JSON.parse(
    '{"' +
      decodeURI(
        urlParams.toString().replace(/&/g, '","').replace(/=/g, '":"')
      ) +
      '"}'
  );

  const urlContent = _.omit(urlParams, ['tenantId', 'destination', 'sender']);

  const [fields, setFields] = useState({
    template: {},
    campaign: {},
    content: urlContent || {},
    destination: urlParams?.destination || '',
    sender: urlParams?.sender || '',
  });

  const resetFields = () => {
    setFields({
      template: {},
      campaign: {},
      content: {},
      destination: '',
      sender: fields.sender,
    });
  };

  const [broadcastMesage] = useMutation(CREATE_MESSAGE);
	// const snackbar = useSnackbar();

  const templateVariable = extractVars(fields.template.message || '');

  const onChange = (e) => {
    let { value, name } = e.target;
    if (name === 'template' || name === 'campaign' || name === 'destination') {
      setFields({
        ...fields,
        [name]: value,
      });
    } else {
      fields.content[name] = urlContent[name] ?? value;
    }
  };
  const sendTemplate = async (e) => {
    console.log(fields.content, 'content');
    console.log(fields, 'fields');

    await broadcastMesage({
      variables: {
        broadcastInput: {
          sender: fields.sender,
          templateId: fields.template.id,
          instance: fields.campaign.instance,
          content: _.omit(fields.content, ['_']),
          scid: fields.destination,
        },
      },
    });


   

    resetFields();
    e.preventDefault();
  };

  return (
    <GridContainer direction="row" justifyContent="center">
      <GridItem xs={8}>
        <Card
          style={{
            minWidth: 275,
            width: '100%',
            marginTop: '5%',
            padding: '15px',
            border: '0.1px solid #c2cad0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h3>Templates Form</h3>
          <Card
            style={{
              display: 'flex',
              flexDirection: 'Column',
              backgroundColor: '#4caf50',
              color: 'white',
              textAlign: 'center',
            }}
          >
            <h4>Template Information</h4>
          </Card>
          <GridContainer direction="row" justifyContent="center">
            <GridItem>
              <GetTemplates value={fields.template} onChange={onChange} />
            </GridItem>
            <GridItem>
              <GetCampaigns value={fields.campaign} onChange={onChange} />
            </GridItem>

            <GridItem>
              <Input
                labelText="DESTINATION"
                inputProps={{
                  disabled: false,
                  name: 'destination',
                  value: fields.destination,
                }}
                formControlProps={{
                  fullWidth: true,
                  onChange: onChange,
                }}
              />
            </GridItem>
          </GridContainer>
          <div style={{ width: '40%' }}>
            <Timeline
              simple
              stories={[
                {
                  inverted: true,
                  badgeColor: 'success',
                  title: 'Message',
                  titleColor: 'success',
                  body:
                    fields.template.message ||
                    "Here it's to display the message that comes with the template.",
                },
              ]}
            />
          </div>
          <div style={{ width: '25%' }}>
            {templateVariable.map((variable, i) => (
              <div>
                <Input
                  formControlProps={{
                    fullWidth: true,
                    onChange: onChange,
                  }}
                  inputProps={{
                    name: `${variable}`,
                    value: fields.content[variable],
                  }}
                  labelText={variable.toUpperCase()}
                ></Input>
              </div>
            ))}
          </div>
          <Button onClick={sendTemplate}>send</Button>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default TemplatesForm;
