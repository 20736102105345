export function extractVars(template, openChar, closeChar) {
  var i = 0;
  var data = [];

  do {
    if (template[i] === "{") {
      for (var j = i + 1; j < template.length; j++) {
        if (template[j] === "}") {
          data[data.length] = template.slice(i + 1, j);
          i = j + 1;
          break;
        }
      }
    }
  } while (++i < template.length);

  return data;
}
